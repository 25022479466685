import React, { Component } from "react";
import _map from "lodash/map";
import PropTypes from "prop-types";

import { IconButton, Tooltip } from "@material-ui/core";
import {
  Audiotrack,
  CheckCircle,
  Image,
  Edit,
  Videocam
} from "@material-ui/icons";

import { PopupMenu } from "../PopupMenu";
import "./Card.scss";

const iconButtonStyle = {
  width: 40,
  height: 40,
  padding: 0
};

const iconButtonEditStyle = {
  right: 0,
  top: 0,
  position: "absolute",
  zIndex: 10000
};

const iconStyle = {
  width: 24,
  height: 24,
  color: "white"
};

export class Card extends Component {
  constructor(props) {
    super(props);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleExclusiveSelect = this.handleExclusiveSelect.bind(this);
    this.state = {
      lastClicked: null
      // loading: true
    };
  }

  handleSelectClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.advancedSelectMode) {
      if (this.props.isCmdPressed) {
        this.props.selectHandler(this.props.id);
      } else if (this.props.isShiftPressed) {
        this.props.shiftSelectHandler(this.props.id);
      } else {
        this.props.exclusiveSelectHandler(this.props.id);
      }
    } else {
      this.props.selectHandler(this.props.id);
    }
  }

  handleExclusiveSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.advancedSelectMode) {
      this.props.selectHandler(this.props.id);
    } else {
      this.props.exclusiveSelectHandler(this.props.id);
    }
  }

  getCoordinatesModal(coordY, coordX) {
    const vpW = document.documentElement.clientWidth;
    const vpH = document.documentElement.clientHeight;

    if (coordX > vpW / 2) {
      coordX -= 400;
    }
    if (coordY > vpH / 2) {
      coordY -= 400;
    }
    return {
      top: coordY,
      left: coordX
    };
  }

  handleEditClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (typeof this.props.editHandler !== "undefined") {
      const rect = this[this.props.id].getBoundingClientRect();
      const modalCoords = this.getCoordinatesModal(rect.top, rect.left);
      const param = this.props.url ? this.props.url : this.props.id;
      this.props.editHandler(param, modalCoords);
    }
  }

  render() {
    const { item, displayCaption, captionPosition, CardContentsComponent } =
      this.props;

    const captionHeight =
      !displayCaption && captionPosition === "inside"
        ? 0
        : this.props.captionHeight;

    let cloudPublicId = null;
    if (!this.props.imgSrc && !this.props.cloudPublicId) {
      cloudPublicId = this.props.placeholderImageCloudId;
    } else if (this.props.cloudPublicId) {
      cloudPublicId = this.props.cloudPublicId;
    }

    // Setting up styles
    const cmsCardStyle = {
      height:
        this.props.imageHeight && !isNaN(captionHeight)
          ? `${this.props.imageHeight + captionHeight} px`
          : "auto",
      background: this.props.backgroundColor
      //'color': this.props.fontColor
    };

    const imgStyle = {
      objectFit: this.props.imageFit
    };

    let positionStyle;
    // convert the captionAlignment into a style to Apply
    // Allows 'left','center','right', 'top-left','top-center','top-right','bottom-left','bottom-center','bottom-right'
    //
    switch (this.props.captionAlignment) {
      case "center":
        positionStyle = {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          margin: 0,
          textAlign: "center"
        };
        break;
      case "left":
        positionStyle = {
          left: 0,
          bottom: 0,
          top: 0,
          right: "auto",
          textAlign: "left"
        };
        break;
      case "right":
        positionStyle = {
          bottom: 0,
          top: 0,
          left: "auto",
          right: 0,
          textAlign: "right"
        };
        break;
      case "top-center":
        positionStyle = {
          bottom: "auto",
          top: 0,
          left: 0,
          right: 0,
          textAlign: "center"
        };
        break;
      case "top-left":
        positionStyle = {
          bottom: "auto",
          top: 0,
          left: 0,
          right: "auto",
          textAlign: "left"
        };
        break;

      case "top-right":
        positionStyle = {
          bottom: "auto",
          top: 0,
          left: "auto",
          right: 0,
          textAlign: "left"
        };
        break;
      case "bottom-center":
        positionStyle = {
          bottom: 0,
          top: "auto",
          left: 0,
          right: 0,
          textAlign: "center"
        };
        break;
      case "bottom-left":
        positionStyle = {
          bottom: 0,
          top: "auto",
          left: 0,
          right: "auto",
          textAlign: "left"
        };
        break;

      case "bottom-right":
        positionStyle = {
          bottom: 0,
          top: "auto",
          left: "auto",
          right: 0,
          textAlign: "left"
        };
        break;
      default:
        positionStyle = {};
        break;
    }

    let titleStyle = {
      height: this.props.captionHeight,
      background: this.props.titleBackgroundColor,
      color: this.props.titleColor,
      ...positionStyle
    };

    const imgConfig =
      this.props.assetConfig &&
      Object.getOwnPropertyNames(this.props.assetConfig).length > 0
        ? this.props.assetConfig
        : this.props.config;

    const selectedStyle = {
      outline: "4px solid " + this.props.selectedBorderColor
    };

    let ImageIcon, VideoIcon, AudioIcon;
    if (this.props.convertTypeToIcon) {
      ImageIcon = <Image />;
      VideoIcon = <Videocam />;
      AudioIcon = <Audiotrack />;
    }

    const extension = /(?:\.([^.]+))?$/;

    let videoImageSrc = "";
    if (!this.props.imgSrc) {
      if (cloudPublicId && this.props.cloudName) {
        if (this.props.type === "video" || this.props.assetType === "video") {
          videoImageSrc = `${location.protocol}//res.cloudinary.com/${this.props.cloudName}/video/upload/w_400/q_auto/`;
          if (imgConfig && imgConfig.posterFrame) {
            videoImageSrc += "so_" + parseFloat(imgConfig.posterFrame, 2) + "/";
          }
          videoImageSrc += `${cloudPublicId}.jpg`;
        }
      }
    }

    return (
      <div
        ref={card => {
          this[this.props.id] = card;
        }}
        style={cmsCardStyle}
        className={`cms-card ${
          this.props.lastSelected === this.props.id ? "last-selected" : ""
        } ${this.props.selected ? "selected" : ""} ${
          this.props.selectMode ? "select-mode" : ""
        }`}
      >
        {displayCaption && captionPosition === "above" ? (
          <div
            key="card-title"
            className={`${captionPosition} title-container`}
            style={titleStyle}
          >
            <span dangerouslySetInnerHTML={{ __html: this.props.title }} />
          </div>
        ) : null}

        <div
          key="card-overlay"
          className="card-overlay"
          style={
            this.props.selected && !displayCaption
              ? {
                  ...selectedStyle
                }
              : {}
          }
          onClick={this.handleSelectClick}
        >
          {this.props.showButtons ? (
            <div key="button-container" className="button-container">
              <Tooltip
                title={`${this.props.selected ? "Deselect" : "Select"} ${
                  this.props.itemName
                }`}
              >
                <IconButton
                  className="icon select"
                  onClick={this.handleExclusiveSelect}
                  style={iconButtonStyle}
                  disableTouchRipple={true}
                >
                  <CheckCircle style={iconStyle} />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}

          {this.props.showButtons && this.props.moreMenuOptions && (
            <PopupMenu
              options={
                this.props.moreMenuOptions &&
                _map(this.props.moreMenuOptions, option => ({
                  value: option.value,
                  label: option.label
                }))
              }
              className="more-options"
              onChange={value =>
                this.props.handleMoreMenuChange(value, this.props.id)
              }
              useIconButton
            />
          )}

          {this.props.showButtons &&
            !this.props.onlySelectMode &&
            ((this.props.onlyOneSelected && this.props.selected) ||
              !this.props.selectMode) && (
              <Tooltip title={`Edit ${this.props.itemName}`}>
                <IconButton
                  className="icon edit"
                  onClick={this.handleEditClick}
                  style={{ ...iconButtonStyle, ...iconButtonEditStyle }}
                  disableTouchRipple={true}
                >
                  <Edit style={iconStyle} />
                </IconButton>
              </Tooltip>
            )}
          {this.props.showButtons ? (
            <div
              key="gradient-top"
              className="gradient-top"
              style={{ height: this.props.vignetteHeight || "auto" }}
            />
          ) : null}
          {this.props.showButtons ? (
            <div
              key="gradient-separator"
              className="gradient-separator"
              style={{ height: this.props.vignetteHeight || "auto" }}
            />
          ) : null}
        </div>
        <div
          key="visible-overlay"
          className="visible-overlay"
          style={
            this.props.selected && displayCaption
              ? {
                  ...selectedStyle,
                  height: `calc(100% - ${this.props.captionHeight}px)`
                }
              : { height: `calc(100% - ${this.props.captionHeight}px)` }
          }
        >
          {this.props.bottomRightIcons ||
          this.props.bottomLeftIcons ||
          (this.props.convertTypeToIcon && this.props.type) ? (
            <div key="bottom-icons" className="bottom-icons">
              <div
                key="bottom-icons-left"
                className="icon-container bottom-icons-left"
              >
                {this.props.bottomLeftIcons ? (
                  <div>{this.props.bottomLeftIcons}</div>
                ) : null}
                {this.props.convertTypeToIcon && !this.props.bottomLeftIcons ? (
                  <div>
                    {this.props.type === "video" ||
                    this.props.assetType === "video"
                      ? VideoIcon
                      : this.props.type === "audio" ||
                        this.props.assetType === "audio"
                      ? AudioIcon
                      : null}
                  </div>
                ) : null}
              </div>
              {this.props.bottomRightIcons ? (
                <div
                  key="bottom-icons-right"
                  className="icon-container bottom-icons-right"
                >
                  <div> {this.props.bottomRightIcons} </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {this.props.bottomRightIcons ||
          this.props.bottomLeftIcons ||
          (this.props.convertTypeToIcon &&
            this.props.type &&
            this.props.type !== "image") ? (
            <div
              key="gradient-bottom"
              className="gradient-bottom"
              style={{ height: this.props.vignetteHeight || "auto" }}
            />
          ) : null}
        </div>
        {!CardContentsComponent ? (
          <div
            key="image-container"
            className="image-container"
            style={{ height: this.props.imageHeight || "auto" }}
          >
            {this.props.imgSrc ? (
              <img
                src={this.props.imgSrc}
                key={this.props.id}
                role="presentation"
                style={imgStyle}
              />
            ) : cloudPublicId && this.props.cloudName ? (
              this.props.type === "video" ||
              this.props.assetType === "video" ? (
                <img src={videoImageSrc} style={imgStyle} />
              ) : (
                <img
                  src={`${location.protocol}//res.cloudinary.com/${
                    this.props.cloudName
                  }/image/upload/${
                    imgConfig && imgConfig.cropCoordinates
                      ? "c_crop,h_" +
                        imgConfig.cropCoordinates.height +
                        ",w_" +
                        imgConfig.cropCoordinates.width +
                        ",x_" +
                        imgConfig.cropCoordinates.x +
                        ",y_" +
                        imgConfig.cropCoordinates.y +
                        "/"
                      : ""
                  }f_auto,q_auto,w_${
                    this.props.cloudCropMaxSize
                  },c_scale/v1/${cloudPublicId}${
                    typeof extension.exec(this.props.filename)[1] !==
                    "undefined"
                      ? ""
                      : ".jpg"
                  }`}
                  style={imgStyle}
                />
              )
            ) : null}
          </div>
        ) : (
          <CardContentsComponent item={item} />
        )}

        {!displayCaption &&
        (captionPosition === "below" || captionPosition === "inside") ? null : (
          <div
            key="title-container-bottom"
            className={`${captionPosition} title-container`}
            style={titleStyle}
          >
            <span dangerouslySetInnerHTML={{ __html: this.props.title }} />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.imgSrc && !this.props.cloudPublicId) {
      // this.setState({loading: false});
      // if (typeof this.props.onImageLoaded !== 'undefined') {
      //   this.props.onImageLoaded();
      // }
    }
  }
}

Card.defaultProps = {
  advancedSelectMode: true,
  type: "image",
  displayCaption: true,
  captionPosition: "below",
  captionAlignment: "left",
  selectMode: false,
  captionHeight: 28,
  imageHeight: 180,
  backgroundColor: "#212121",
  // vignetteFromColor: 'rgba(0, 0, 0, 0.38)',
  // vignetteToColor: 'transparent',
  fontColor: "rgba(0, 0, 0, 0.87)",
  vignetteHeight: 85,
  titleBackgroundColor: "#2E2E2E",
  titleColor: "rgba(255, 255, 255, 0.30)",
  selectedBorderColor: "#029bE5",
  showButtons: true,
  imageFit: "contain",
  isCloudinary: false,
  cloudCropMaxSize: 600,
  onlySelectMode: false,
  config: null,
  cloudName: "dgpzfuyzy",
  assetConfig: {}
};

Card.propTypes = {
  // Data
  url: PropTypes.string, // URL to pass to the editHandler
  type: PropTypes.string, // Card type for formatting options
  imgSrc: PropTypes.string, // SRC of the card image
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Title of the card to display in the caption - HTML admitted
  subtitle: PropTypes.string,
  config: PropTypes.object, // Additional config such as cropping config
  advancedSelectMode: PropTypes.bool, // this flag forces the bind between select and exclusiveSelect
  // Select states
  selected: PropTypes.bool,
  selectMode: PropTypes.bool,
  onlyOneSelected: PropTypes.bool,
  lastSelected: PropTypes.string,
  onlySelectMode: PropTypes.bool, // Hides the editing pencil icon-button
  // Layout
  captionType: PropTypes.string, // Allows 'hover-bottom', 'hover-top','hover-center','under', null
  displayCaption: PropTypes.bool,
  imageHeight: PropTypes.number,
  convertTypeToIcon: PropTypes.bool,
  itemName: PropTypes.string, // NAme of the item for tooltips
  bottomLeftIcons: PropTypes.node, // A node with icons or components to be injected in the bottom
  bottomRightIcons: PropTypes.node, // A node with icons or components to be injected in the bottom
  placeholderImageCloudId: PropTypes.string,
  captionPosition: PropTypes.string, // Allows 'over', 'below'
  captionAlignment: PropTypes.string, // Allows 'left','center','right', 'top-left','top-center','top-right','bottom-left','bottom-center','bottom-right'
  captionHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vignetteHeight: PropTypes.number,
  showButtons: PropTypes.bool,
  imageFit: PropTypes.string, // How the image fits in the container: options (fill, cover, contain, scale-down, none)
  // Styles
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  // vignetteFromColor: PropTypes.string,
  // vignetteToColor: PropTypes.string,
  titleBackgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  selectedBorderColor: PropTypes.string,
  // Action Handlers
  editHandler: PropTypes.func,
  selectHandler: PropTypes.func,
  shiftSelectHandler: PropTypes.func,
  exclusiveSelectHandler: PropTypes.func,
  // Key States
  isCmdPressed: PropTypes.bool,
  isShiftPressed: PropTypes.bool,
  // CLOUDINARY - Configuration
  isCloudinary: PropTypes.bool,
  cloudName: PropTypes.string, // Cloudinary Name
  cloudPublicId: PropTypes.string, // Cloudinary Public Id
  cloudCropMaxSize: PropTypes.number // Resizing card image when fetching from Cloudinary
  // onImageLoaded: PropTypes.func, // Executes this callback when the image has been loaded
  // allCardsLoaded: PropTypes.bool // Flag that indicates that all cards have been loaded
};
