import React, { Component } from "react";
import PropTypes from "prop-types";

import { Slider as MUISlider } from "@material-ui/core";

import "./Slider.scss";

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  handleChange(event, value) {
    this.setState({ value });
    this.props.handleChange;
  }

  render() {
    return (
      <div className="row cms-list-item cms-slider">
        <div className="col-md-6 left-item">
          <span className="list-item-text">{this.props.label}</span>
        </div>
        <div className="col-md-6 right-item">
          <span className="list-item-text">{this.state.value} </span>
        </div>
        <MUISlider
          min={0}
          max={100}
          step={1}
          value={parseInt(this.state.value, 10)}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Slider.defaultProps = {
  selectedValue: "",
  item: {
    name: ""
  }
};

Slider.propTypes = {
  selectedValue: PropTypes.string,
  item: PropTypes.object
};
